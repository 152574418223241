<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Contact details</h3>
        </div>
      </div>
      <div>
        <vs-row>
          <div class="px-8 mt-6 w-full pb-8">
            <vs-row>
              <vs-col vs-w="4" class="textBlack">Communication type</vs-col>
              <vs-col vs-w="4" class="textBlack">Email address</vs-col>
              <vs-col vs-w="4" class="textBlack">Phone number</vs-col>
            </vs-row>
            <hr class="line-hr-thin mt-2 mb-2">
            <vs-row v-for="(contact, index) in contactDetails" :key="index" class="mt-8">
              <vs-col vs-w="4"><p>{{ contact.name }}</p></vs-col>
              <vs-col vs-w="4">
                <vs-input :name="`email[${index}]`" v-model="contact.email" class="w-full" data-vv-as="email address" v-validate="'required|email'" @change="formChange" />
                <span v-show="errors.has(`email[${index}]`)" class="text-danger text-sm">{{ errors.first(`email[${index}]`) }}</span>
              </vs-col>
              <vs-col vs-w="4">
                <vs-input
                  :name="`phone[${index}]`"
                  v-model="contact.phoneNumber"
                  v-mask="numberMask[index].mask"
                  class="w-full"
                  data-vv-as="phone number"
                  v-validate="'required|phoneFormat'"
                  v-on:input="checkValidPhoneNumber(index)"
                  @change="formChange"
                />
                <span v-show="errors.has(`phone[${index}]`)" class="text-danger text-sm">{{ errors.first(`phone[${index}]`) }}</span>
              </vs-col>
            </vs-row>
          </div>
        </vs-row>
        <vs-row class="block">
          <div class="button-section">
            <vs-button class="primary lg:mr-8 mr-4" @click="updateContact" :disabled="!isValidField || !validateForm || !enableSubmit" size="medium" v-round>Save</vs-button>
            <div class="mid-blue" @click="$router.push({ name: 'settings' })">
              <u>Cancel</u>
            </div>
          </div>
        </vs-row>
      </div>
    </vs-card>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { Validator } from "vee-validate";

export default {
  directives: { mask },

  data() {
    return {
      contactDetails: [],
      numberMask: [
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
        {
          mask: "#### ### ###"
        },
      ],
      enableSubmit: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
    isValidField() {
      let status = true;

      if (this.contactDetails) {
        this.contactDetails.map((item) => {
          if (item.email === "" || item.phoneNumber === "") {
            status = false;
          }
        });
      }

      return status;
    },
    validateForm() {
      return !this.errors.any();
    },
  },

  methods: {
    ...mapActions("partner", ["fetchPartnerContactDetails", "updateContactDetails"]),

    fetchContactDetails() {
      this.fetchPartnerContactDetails(this.partnerId).then((res) => {
        this.contactDetails = res.data.data;
      });
    },

    checkValidPhoneNumber(value) {
      let checkNumber = this.contactDetails[value].phoneNumber;
      this.numberMask[value].mask = this.checkDynamicPhoneNumber(checkNumber);
    },

    checkDynamicPhoneNumber(checkNumber) {
      let mask = "#### ### ###";

      if (checkNumber && checkNumber.length > 0) {
        let numLength = checkNumber.length;
        let numberOne = "";
        let numberTwo = "";

        if (numLength >= 2) {
          numberOne = checkNumber.substring(0, 2);

          if (numberOne === "13") {
            numberTwo = checkNumber.split(" ").join("").substring(0, 4);
          }
        }

        if (["02", "03", "07", "08"].includes(numberOne)) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (numberOne === "13") {
          if (parseInt(numberTwo) == 1300) {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }

      return mask;
    },

    async updateContact() {
      this.$vs.loading();

      await this.updateContactDetails({ contactDetails: this.contactDetails }).then((res) => {
        this.enableSubmit = false;
        this.$vs.loading.close();
        this.showMessage("Success", "Contact details updated.", "success", "icon-check-circle");
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
      });
    },

    formChange() {
      this.enableSubmit = true;
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },

  async created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });
  },

  mounted() {
    this.fetchContactDetails();
  }
}
</script>
